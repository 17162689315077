import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button, Container, Box } from '@material-ui/core'
import { AuthUserContext } from '../../PublicPages/Session'

import { useHistory } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: 32,
    margin: 30,
    fontWeight: 'bold'
  },
  boxSubTitle: {
    width: 400,
    alignSelf: 'center'
  },
  subTitle: {
    fontSize: 22,
  }
})

function CustomButton (props) {
  const { children } = props
  return <Button variant='contained' style={{width: 400, margin: 4}} color='primary' {...props} >{children}</Button>
}

export default function HomePage () {
  const classes = useStyles()

  const user = useContext(AuthUserContext)
  const history = useHistory()

  return (
    <Container className={classes.container} >
      <Typography className={classes.title}>Meinen Account verwalten</Typography>
      <Box className={classes.boxSubTitle}>
        <Typography className={classes.subTitle}>
          Hallo {user.displayName ? user.displayName : 'lieber Nutzer'}. Auf dieser Seite kannst du verschiedene Account-Eintsellungen verwalten.
        </Typography>
      </Box>
      <Box height={20} />
      <CustomButton onClick={() => history.push(ROUTES.ACCOUNT_PROFILE)}>Meine Daten</CustomButton>
      <CustomButton onClick={() => history.push(ROUTES.ACCOUNT_CHANGE_EMAIL)}>Meine E-Mail ändern</CustomButton>
      <CustomButton onClick={() => history.push(ROUTES.ACCOUNT_CHANGE_PW)}>Mein Passwort ändern</CustomButton>
      <CustomButton onClick={() => history.push(ROUTES.ACCOUNT_DELETE)}>Meinen Account löschen</CustomButton>
    </Container>
  )
}
