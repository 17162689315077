import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyDHJvhZ4AOLmTqnf95dO1o6OvZet0m-PaY',
  authDomain: 'picsup-7191d.firebaseapp.com',
  databaseURL: 'https://picsup-7191d.firebaseio.com',
  projectId: 'picsup-7191d',
  storageBucket: 'picsup-7191d.appspot.com',
  messagingSenderId: '93082395948',
  appId: '1:93082395948:web:1dc875ce662b9814107889'
}

export const firebase = app.initializeApp(config)
export const auth = app.auth()
export const db = app.firestore()
export const storage = firebase.storage()