import React, { useState, useEffect, useContext } from 'react'
import app from 'firebase/app'

import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import { db } from '../../PublicPages/Firebase/firebase'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, CardActions, Snackbar } from '@material-ui/core'
import { AuthUserContext } from '../../PublicPages/Session'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { FirebaseContext } from '../../PublicPages/Firebase'

const db_collection_name = 'partner'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 24,
    margin: 30,
    fontWeight: 'bold'
  },
})

function useInput (value, setValue) {
  return {
    value,
    onChange: (event) => {
      setValue(event.target.value)
    }
  }
}

export default function ChangePasswordPage () {
  const classes = useStyles()

  const user = useContext(AuthUserContext)
  const firebase = useContext(FirebaseContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [open, setOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const [oldPassword, setOldPassword] = useState('')
  const [email, setEmail] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')

  const oldPasswordInput = useInput(oldPassword, setOldPassword)
  const newPassword1Input = useInput(newPassword1, setNewPassword1)
  const newPassword2Input = useInput(newPassword2, setNewPassword2)

  // Beim Start Daten laden
  useEffect(
    () => {
      setLoading(true)
      setEmail(user.email)
      setLoading(false)
    },
    []
  )

  async function speichern () {
    const credential = app.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    )
    // const credential = firebase.auth().EmailAuthProvider.credential(email, oldPassword);
    user.reauthenticateWithCredential(credential).then(function() {
      // User re-authenticated.
      user.updatePassword(newPassword1).then(function() {
        // Update successful.
        setOpen(true)
        setSnackMessage('Dein Passwort wurde erfolgreich geändert!')
      }).catch(function(error) {
        // An error happened.
        setOpen(true)
        setSnackMessage('Ein Fehler ist aufgetreten! Dein Passwort wurde nicht geändert')
        console.log(error)
      });
    
    }).catch(function(error) {
      // An error happened.
        setOpen(true)
        setSnackMessage('Ein Fehler ist aufgetreten! Dein Passwort wurde nicht geändert')
        console.log(error)
    });

  }

  return (
    <GridContainer style={{ paddingTop: 20, justifyContent: 'center'}} >
      <GridItem xs={12} sm ={12} md={8}>
        <Card>
          <Typography className={classes.title}>
            Passwort ändern
          </Typography>
          {loading
            ? <CircularProgress/>
            : <>
              <CardContent style={{ margin: 20 }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label='Altes Passwort'
                      fullWidth
                      type="password"
                      {...oldPasswordInput} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label='Neues Passwort'
                      fullWidth
                      type="password"
                      {...newPassword1Input} />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      label='Neues Passwort wiederholen'
                      fullWidth
                      type="password"
                      {...newPassword2Input} />
                  </GridItem>
                </GridContainer>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={speichern} >
                Speichern
                </Button>
              </CardActions>
            </>
          }
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={snackMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          } />
      </GridItem>
    </GridContainer>
  )
}
