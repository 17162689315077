import React, { useState, useContext } from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import app from 'firebase/app'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, Snackbar, Container } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { AuthUserContext } from '../../PublicPages/Session'

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    margin: 30,
    fontWeight: 'bold'
  },
})

function useInput (value, setValue) {
  return {
    value,
    onChange: (event) => {
      setValue(event.target.value)
    }
  }
}

export default function DeleteAccountPage () {
  const classes = useStyles()

  const user = useContext(AuthUserContext)
  const [oldPassword, setOldPassword] = useState('')
  const oldPasswordInput = useInput(oldPassword, setOldPassword)
  const [loading, setLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  function handleClick(){

    const credential = app.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    )
    // const credential = firebase.auth().EmailAuthProvider.credential(email, oldPassword);
    user.reauthenticateWithCredential(credential).then(function() {
      // User re-authenticated.
      user.delete().then(function() {
        // Update successful.
        setOpen(true)
        setSnackMessage('Dein Account wurde erfolgreich gelöscht!')
      }).catch(function(error) {
        // An error happened.
        setOpen(true)
        setSnackMessage('Ein Fehler ist aufgetreten! Dein Account wurde nicht gelöscht')
        console.log(error)
      });
    
    }).catch(function(error) {
      // An error happened.
        setOpen(true)
        setSnackMessage('Ein Fehler ist aufgetreten! Dein Account wurde nicht gelöscht')
        console.log(error)
    });
  }

  return (
    <Container className={classes.container} >
      <GridContainer style={{ paddingTop: 20, justifyContent: 'center'}} >
        <GridItem xs={12} sm ={12} md={8}>
          <Card>
            <Typography className={classes.title}>
              Account löschen
            </Typography>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      style={{marginLeft: 20, marginRight: 60}}
                      label='Dein Passwort'
                      fullWidth
                      type="password"
                      {...oldPasswordInput} />
                  </GridItem>
            <Button color={"secondary"} variant={"contained"} style={{margin: 20}} onClick={handleClick}>Jetzt meinen Account löschen</Button>
          </Card>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Dein Account wurde erfolgreich gelöscht"
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            } />
        </GridItem>
      </GridContainer>
    </Container>
  )
}
