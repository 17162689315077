import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme()
theme.palette.primary.dark = '#1AA6B7' // eastern blue
theme.palette.primary.main = '#002D40' // prussian blue
theme.palette.secondary.main = '#F56A79' // froly
theme.palette.secondary.dark = '#FF414D' // coral red
theme.palette.background.main = '#D9EDF2' // iceberg

export default theme
