import React, { useState, useEffect, useContext } from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import { db } from '../../PublicPages/Firebase/firebase'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Container, Button, CardActions, Snackbar, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { AuthUserContext } from '../../PublicPages/Session'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const db_collection_name = 'users'

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    margin: 30,
    fontWeight: 'bold'
  },
})

function useInput (value, setValue) {
  return {
    value,
    onChange: (event) => {
      setValue(event.target.value)
    }
  }
}

export default function ProfilePage () {
  const classes = useStyles()

  const user = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  // roles: "locationPartner"
  const [birthday, setBirthday] = useState('')
  const [gender, setGender] = useState('')
  const [nachname, setNachname] = useState('')
  const [vorname, setVorname] = useState('')

  const birthdayInput = useInput(birthday, setBirthday)
  const genderInput = useInput(gender, setGender)
  const nachnameInput = useInput(nachname, setNachname)
  const vornameInput = useInput(vorname, setVorname)

  // Beim Start Daten laden
  useEffect(
    () => {
      setLoading(true)

      const doc = db.collection(db_collection_name).doc(user.uid).get()
        .then(snapShot => {
          const data = snapShot.data()
          console.log(data)

          // if(data.birthday){
          //   const date = new Date()
          //   date.setDate(data.birthday.substr(0,2))
          //   date.setMonth(data.birthday.substr(3,2) - 1)
          //   date.setFullYear(data.birthday.substr(6,4))
          //   setBirthday(date)
          //   console.log(date)
          // }
          data.birthday ? setBirthday(data.birthday) : setBirthday(null)
          data.gender ? setGender(data.gender) : setGender(null)
          data.nachname ? setNachname(data.nachname) : setNachname(null)
          data.vorname ? setVorname(data.vorname) : setVorname(null)

          setLoading(false)
        })
        .catch(err => {
          console.log('Error getting documents: ', err)
          setError(err)
          setLoading(false)
        })
    },
    []
  )

  async function speichern () {
    const document = {
      birthday,
      gender,
      nachname,
      vorname
    }

    db.collection(db_collection_name).doc(user.uid).update(document)
      .then(() => {
        console.log('Erfolgreich gespeichert')
        setOpen(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Container className={classes.container} >
      
      <GridContainer style={{ paddingTop: 20, justifyContent: 'center'}} >
        <GridItem xs={12} sm ={12} md={8}>
          <Card style={{width: '100%'}}>
            <Typography className={classes.title}>Meinen Daten verwalten</Typography>
            {loading
              ? <CircularProgress/>
              : <>
                <CardContent style={{ margin: 20 }}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        label='Vorname'
                        fullWidth
                        {...vornameInput}/>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        label='Nachname'
                        fullWidth
                        {...nachnameInput}/>
                    </GridItem>
                  </GridContainer>
                  <div style={{ height: 20 }} />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Geschlecht</InputLabel>
                      <Select
                        label='Geschlecht'
                        style={{width: 300}}
                        {...genderInput}
                      >
                        <MenuItem value={'m'}>Männlich</MenuItem>
                        <MenuItem value={'w'}>Weiblich</MenuItem>
                        <MenuItem value={'d'}>Divers</MenuItem>
                      </Select>
                    </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                    
                      <TextField
                        label='Geburtstag'
                        style={{width: 300}}
                        InputLabelProps={{
                          shrink: true,
                        }}

                        {...birthdayInput} 
                        />
                    </GridItem>
                  </GridContainer>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={speichern} >
                  Speichern
                  </Button>
                </CardActions>
              </>
            }
          </Card>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Gespeichert"
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            } />
        </GridItem>
      </GridContainer>
    </Container>
  )
}
