import React, {
  useState, useEffect, useContext
} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  HashRouter as Router, Route, Switch
} from 'react-router-dom'

import * as ROUTES from '../../../constants/routes'

import theme from '../../../constants/theme'

import LandingPage from '../Landing'
import ResetPasswordPage from '../ResetPassword'
import { FirebaseContext } from '../Firebase'
import { AuthUserContext } from '../Session'
import ProtectedContainer from '../../ProtectedPages/Home/ProtectedContainer'

function PrivateRoute ({ path, component }) {
  const user = useContext(AuthUserContext)
  if (user) {
    return <Route path={path} component={component} />
  }
  console.log('fallback to landingPage')
  return <Route path="/" component={LandingPage} />
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired
}

function App () {
  const firebase = useContext(FirebaseContext)
  const [authUser, setAuthUser] = useState()

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        setAuthUser(user)
        console.log('User eingeloggt!')
        // ...
      } else {
        // User is signed out.
        setAuthUser(null)
        console.log('User ausgeloggt!')
      }
    })
    return () => {
      unlisten()
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AuthUserContext.Provider value={authUser}>
        <Router>
          <Switch>
            <PrivateRoute path={ROUTES.ACCOUNT} component={ProtectedContainer} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
            <Route component={LandingPage} />
          </Switch>
        </Router>
      </AuthUserContext.Provider>
    </ThemeProvider>
  )
}
export default App
