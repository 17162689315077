import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

// Roboto Font für Material-UI
import 'fontsource-roboto'

import bgImg from './../../../res/LandingPageBackground01.png'
import titleImg from './../../../res/Logo_PICSUP_black.png'

import * as ROUTES from '../../../constants/routes'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { FirebaseContext } from '../Firebase'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(4),
    width: 100
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    fontSize: 16,
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}))

const INITIAL_VALUES = {
  email: '',
  password: ''
}

function validateAuth (values) {
  const errors = {}

  // email validation
  if (!values.email) {
    errors.email = 'Bitte gib eine E-Mail-Adresse ein'
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Ungültige E-Mail-Adresse'
  }

  // password validation
  if (!values.password) {
    errors.password = 'Bitte gib ein Passwort ein'
  }

  return errors
}

function HashLink ({ children }) {
  const classes = useStyles()
  return <RouterLink
    className={classes.link}
    to={ROUTES.RESET_PASSWORD}
  >
    {children}
  </RouterLink>
}

HashLink.propTypes = {
  children: PropTypes.node
}

function LandingPage () {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const [values, setValues] = useState(INITIAL_VALUES)
  const [errors, setErrors] = useState({})
  const [firebaseError, setFirebaseError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  async function login () {
    const { email, password } = values
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      history.push(ROUTES.ACCOUNT)
    } catch (error) {
      setFirebaseError(error.message)
    }
  }

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
        login()
        setIsSubmitting(false)
      } else {
        setIsSubmitting(false)
      }
    }
  }, [errors])

  function handleChange (event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  function handleBlur (event) {
    event.preventDefault()
    const validationErrors = validateAuth(values)
    setErrors(validationErrors)
  }

  function handleSubmit (event) {
    event.preventDefault()
    const validationErrors = validateAuth(values)
    setErrors(validationErrors)
    setIsSubmitting(true)
  }

  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={titleImg} className={classes.logo} />
          <Typography component="h1" variant="h5">
            Melde dich bei PICSUP an
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              // autoFocus
              error={errors.email}
              label="E-Mail-Adresse"
              helperText={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password}
              label="Passwort"
              helperText={errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {firebaseError && <Typography color="secondary">{firebaseError}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Anmelden
            </Button>
            <Grid container>
              <Grid item xs>
                <HashLink>
                  Passwort vergessen?
                </HashLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default LandingPage
