import React, { useState, useEffect, useContext } from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import { db } from '../../PublicPages/Firebase/firebase'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, CardActions, Snackbar } from '@material-ui/core'
import { AuthUserContext } from '../../PublicPages/Session'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const db_collection_name = 'partner'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 24,
    margin: 30,
    fontWeight: 'bold'
  },
})

function useInput (value, setValue) {
  return {
    value,
    onChange: (event) => {
      setValue(event.target.value)
    }
  }
}

export default function ChangeEmailPage () {
  const classes = useStyles()

  const user = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [open, setOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const [email, setEmail] = useState('')

  const emailInput = useInput(email, setEmail)

  // Beim Start Daten laden
  useEffect(
    () => {
      setLoading(true)
      const {displayName, email, uid} = user
      setEmail(email)
      setLoading(false)
    },
    []
  )

  async function speichern () {
    user.updateEmail(email).then(() => {
      // Update successful.
      setOpen(true)
      setSnackMessage('Deine E-Mail-Adresse wurde erfolgreich geändert!')
    }).catch(function(error) {
      // An error happened.
      setOpen(true)
      setSnackMessage('Ein Fehler ist aufgetreten! Deine E-Mail-Adresse wurde nicht geändert')
      console.log(error)
    });
    
  }

  return (
    <GridContainer style={{ paddingTop: 20, justifyContent: 'center'}} >
      <GridItem xs={12} sm ={12} md={8}>
        <Card  style={{ margin: 20, paddingLeft: 100, paddingRight: 100 }}>
          <Typography className={classes.title}>
            E-Mail-Adresse ändern
          </Typography>
          {loading
            ? <CircularProgress/>
            : <>
              <CardContent>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label='E-Mail-Adresse'
                      fullWidth
                      {...emailInput} />
                  </GridItem>
                </GridContainer>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={speichern} >
                Speichern
                </Button>
              </CardActions>
            </>
          }
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={snackMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          } />
      </GridItem>
    </GridContainer>
  )
}
