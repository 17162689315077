import React, { useState, useEffect, useContext } from 'react'

// Roboto Font für Material-UI
import 'fontsource-roboto'

import bgImg from './../../../res/LandingPageBackground02.png'
import titleImg from './../../../res/Logo_PICSUP_black.png'

import { FirebaseContext } from '../Firebase'
import * as ROUTES from '../../../constants/routes'

import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(4),
    width: 100
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

function validateEmail (email) {
  const errors = {}

  // email validation
  if (!email) {
    errors.email = 'Bitte gib eine E-Mail-Adresse ein'
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
  ) {
    errors.email = 'Ungültige E-Mail-Adresse'
  }

  return errors
}

function ResetPasswordPage () {
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)

  const history = useHistory()
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [firebaseError, setFirebaseError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')

  async function resetPassword () {
    try {
      await firebase.auth().sendPasswordResetEmail(email)
      history.push(ROUTES.LANDING)
    } catch (error) {
      setFirebaseError(error.message)
    }
  }

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
        resetPassword()
        setIsSubmitting(false)
      } else {
        setIsSubmitting(false)
      }
    }
  }, [errors])

  function handleChange (event) {
    setEmail(event.target.value)
  }

  function handleBlur (event) {
    event.preventDefault()
    const validationErrors = validateEmail(email)
    setErrors(validationErrors)
    setEmailError(validationErrors.email)
  }

  function handleSubmit (event) {
    event.preventDefault()
    const validationErrors = validateEmail(email)
    setErrors(validationErrors)
    setEmailError(validationErrors.email)
    setIsSubmitting(true)
  }

  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={titleImg} className={classes.logo} />
          <Typography component="h1" variant="h5">
            Setze dein Passwort zurück
          </Typography>
          <Box m={2} />
          <Typography variant='body2'>
            Bitte gib deine E-Mail-Adresse ein und wir senden dir einen Link zu.
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              error={emailError}
              label="E-Mail-Adresse"
              helperText={emailError}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Jetzt Passwort zurücksetzen
            </Button>

          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default ResetPasswordPage
