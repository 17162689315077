import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { Route, Switch, useHistory } from 'react-router-dom'

import * as ROUTES from '../../../constants/routes'
import { FirebaseContext } from '../../PublicPages/Firebase'

import titleImg from './../../../res/Logo_PICSUP_neu.png'
import HomePage from './HomePage'
import ProfilePage from '../Profile'
import ChangePasswordPage from '../ChangePassword'
import ChangeEmailPage from '../ChangeEmail'
import DeleteAccountPage from '../DeleteAccount'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 50,
    textAlign: 'center'
  },
  nested: {
    paddingLeft: theme.spacing(8)
  },
  logo: {
    maxWidth: 120,
    height: 50,
    margin: 10
  }
}))

export default function ProtectedContainer () {
  const classes = useStyles()
  const history = useHistory()

  const firebase = useContext(FirebaseContext)
  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  function handleLogout () {
    setShowLogoutDialog(false)
    firebase.auth().signOut()
    history.push(ROUTES.LANDING)
  }

  function toggleLogoutDialog () {
    setShowLogoutDialog(!showLogoutDialog)
  }

  return (
    <div className={classes.root}>
      <Dialog open={showLogoutDialog} onClose={toggleLogoutDialog} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            Jetzt abmelden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleLogoutDialog} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleLogout} color="secondary">
            Abmelden
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <span style={{ flexGrow: 1 }} />
          <img src={titleImg} className={classes.logo} onClick={() => history.push(ROUTES.ACCOUNT)} alt='Logo' />
          <span style={{ flexGrow: 1 }} />
          <Button startIcon={<ExitToAppIcon style={{fontSize: 30}} />} onClick={toggleLogoutDialog} color="inherit"/>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Switch>
          <Route path={`${ROUTES.ACCOUNT_PROFILE}/`}><ProfilePage /></Route>
          <Route path={`${ROUTES.ACCOUNT_CHANGE_PW}/`}><ChangePasswordPage /></Route>
          <Route path={`${ROUTES.ACCOUNT_CHANGE_EMAIL}/`}><ChangeEmailPage /></Route>
          <Route path={`${ROUTES.ACCOUNT_DELETE}/`}><DeleteAccountPage /></Route>
          <Route path={`${ROUTES.ACCOUNT}/`}><HomePage /></Route>
        </Switch>
      </main>
    </div>
  )
}
